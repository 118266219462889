.infoFin{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 30px 30px;
    .header{
        margin-bottom: 20px;
        h4{
            font-weight: 600;
            font-size: 30px;
            line-height: 45px;
            color: #145CCC;
            margin: 10px 0px;
        }
        p{
            font-weight: 400;
            font-size: 20px;
            line-height: 30px;
            color: #000000;
            margin: 10px 0px;
        }
    }
    .popupfield{
        display: flex;
        justify-content: flex-end;
        margin: 10px 0px;
        button{
            background-color: #145CCC;
            height: 36px;
            width: 30%;
            border-radius: 18px;
            border: none;
            color: #fff;
        }
        @media screen and (max-width: 768px) {
            button{
                width: 100%;
            }
        }
    }
    .body{
        display: flex;
        justify-content: center;
        .body1{
            text-align: left;
            padding: 20px;
            margin: 10px 20px;
            border-radius: 44px;
            border: 1px solid #145CCC;
            .title{
                font-weight: 500;
                font-size: 20px;
                line-height: 30px;
                color: #4A4A4A;
                margin: 10px 0px 10px;
                padding-left: 1rem;
            }
            ul{
                padding-left: 1rem;
                display: flex;
                margin-bottom: 10px;
                li{
                    width: 100%;
                    margin-right: 20px;
                    label{
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 18px;
                        color: #000000;
                        margin: 8px 3px;
                    }
                    input{
                        width: 100%;
                        border: 1px solid #4A4A4A;
                        border-radius: 18px;
                        padding-left: 15px;
                        height: 30px;
                        &:focus{
                            outline: none;
                        }
                    }
                    textarea{
                        margin-top: 20px;
                        width: 100%;
                        border-radius: 18px;
                        padding: 10px 0px 0px 10px;
                        height: 76px;
                    }
                }
            }
            @media (max-width: 768px) {
                ul{
                    flex-direction: column;
                    padding: 0px;
                    li{
                        margin-right: 0px;
                    }
                }

            }
            .title-bottom{
                font-weight: 500;
                font-size: 20px;
                line-height: 30px;
                color: #4A4A4A;
                margin: 10px 0px 30px;
                padding-left: 1rem;
            }
            .slide-switch{
                padding-left: 1rem;
                margin-bottom: 30px;
                p{
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 18px;
                    color: #000000;
                    margin: 10px 0px;
                }
                .swicth-tab{
                    display: flex;
                    align-items: center;
                    p{
                        font-size: 14px;
                        line-height: 21px;
                        color: #A19F9D;
                        margin: 0px 10px;
                    }
                }
            }
        }
        .body2{
            text-align: left;
            padding: 20px;
            margin: 10px 20px;
            border-radius: 44px;
            border: 1px solid #145CCC;
            .title{
                font-weight: 500;
                font-size: 20px;
                line-height: 30px;
                color: #4A4A4A;
                margin: 10px 0px 10px;
                padding-left: 1rem;
            }
            ul{
                padding-left: 1rem;
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                li{
                    width: 100%;
                    margin-right: 20px;
                    p{
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 32px;
                        color: #000000;
                        margin: 0px;
                    }
                    label{
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 18px;
                        color: #000000;
                        margin: 8px 3px;
                    }
                    input{
                        width: 100%;
                        border: 1px solid #4A4A4A;
                        border-radius: 18px;
                        padding-left: 15px;
                        height: 30px;
                        &:focus{
                            outline: none;
                        }
                    }
                    .swicth-tab{
                        display: flex;
                        align-items: center;
                        p{
                            font-size: 14px;
                            line-height: 21px;
                            color: #A19F9D;
                            margin: 0px 10px;
                        }
                    }
                }
            }
            @media (max-width: 768px) {
                ul{
                    flex-direction: column;
                    padding: 0px;
                    li{
                        margin-right: 0px;
                    }
                }

            }
            .textSlide{
                justify-content: space-between;
                align-items: flex-end;
                li{
                    width: auto;
                }
            }
            .bottom{
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                .title-bottom{
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 30px;
                    color: #4A4A4A;
                    margin-bottom: 30px;
                }
                .sub-title{
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    color: #000000;
                    margin-bottom: 20px;
                }
                button{
                    border-radius: 18px;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 21px;
                    color: #FFFFFF;    
                    margin-bottom: 10px;
                    width: auto;
                    background: #145CCC;
                    border: none;
                    width: 20%;   
                    padding: 5px 0px;            
                }
                @media (max-width: 768px) {
                    button{
                        width: 100%;
                    }
                }
            }
        }
    }
    @media (max-width: 768px) {
        .body{
            flex-direction: column;
        }
    }
}