@charset "UTF-8";
@import '../../assets/styles/brand-colors';
@import url('https://fonts.googleapis.com/css?family=Poppins:400,600,700,400italic');
@import url('https://fonts.googleapis.com/css?family=Bebas+Neue:400,600,700,400italic');

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiGyp8kv8JHgFVrJJLucXtAOvWDSHFF.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiGyp8kv8JHgFVrJJLufntAOvWDSHFF.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiGyp8kv8JHgFVrJJLucHtAOvWDSA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJbecnFHGPezSQ.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJnecnFHGPezSQ.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z11lFd2JQEl8qw.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1JlFd2JQEl8qw.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1xlFd2JQEk.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z11lFd2JQEl8qw.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z1JlFd2JQEl8qw.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z1xlFd2JQEk.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Bebas Neue';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/bebasneue/v9/JTUSjIg69CK48gW7PXoo9WdhyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Bebas Neue';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/bebasneue/v9/JTUSjIg69CK48gW7PXoo9WlhyyTh89Y.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

h1.title-red {
  font-family: Poppins, serif;
  font-size: 30px;
  font-weight: 600;
  line-height: 45px;
  letter-spacing: 0;
  text-align: center;
  color: $red-1;
  margin: 4px 0;
}

h3.sub-title,
h3.sub-mobile-title {
  font-family: Poppins, serif;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  text-align: center;
  margin: 2px 0;
  color: #1C3464;
}

h3.sub-title {
  font-size: 20px;
}

h3.sub-mobile-title {
  font-size: 18px;
  line-height: 128%;
}

h3.sub-title-left {
  font-family: Poppins, serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0;
  text-align: left;
  margin: 4px 0;
}

h1.title-message,
h1.title-message-mobile {
  font-family: Bebas Neue, serif;
  font-size: 40px;
  font-weight: 400;
  line-height: 46px;
  letter-spacing: 0;
  text-align: center;
  margin: 4px 0;
}

h1.title-message {
  font-size: 40px;
}

h1.title-message-mobile {
  font-size: 18px;
}

h1.title-message-mobile-product-red,
h1.title-message-product-red {
  font-family: Bebas Neue, serif;
  font-weight: 400;
  line-height: 96px;
  letter-spacing: 0;
  text-align: center;
  color: $red-1;
  margin: 4px 0;
}

h1.title-message-mobile-product-red {
  font-size: 75px;
}

h1.title-message-mobile-product-red {
  font-size: 50px;
}

h1.title-message-price {
  font-family: Poppins, serif;
  font-size: 50px;
  font-weight: 400;
  line-height: 54px;
  letter-spacing: 0;
  text-align: center;
  margin: 4px 0;
}

p.step {
  font-family: Poppins, serif;
  font-size: 10.5px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
}

.label-form {
  font-family: Poppins, serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  color: $black;
}

.label-form-mobile {
  width: 102px;
  height: 18px;
  left: 0;
  top: 0;

  font-family: 'Poppins', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* color/black */

  color: #000000;

}


.title-medium-red,
.subtitle-medium,
.subtitle-medium-mobile,
.title-large,
.title-large-mobile,
.title-medium-red-mobile,
.subtitle-medium-mobile,
.toggle-label
{
  font-family: 'Poppins', serif;
  font-style: normal;
}

.title-large-red,
.title-large-bold,
.title-large-bold-mobile,
.title-large-red-mobile {
  font-family: 'Bebas Neue', serif;
  font-style: normal;
  font-weight: 400;
}

.title-medium-red {
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  color: #145CCC;
}

.title-large-red {
  font-size: 75px;
  line-height: 128%;
  text-align: center;
  color: #145CCC;
}

.title-large-red-mobile {
  font-size: 50px;
  line-height: 64px;
  text-align: center;
  color: #145CCC;
}

.subtitle-medium {
  font-weight: 400;
  font-size: 16px!important;
  line-height: 30px;
  color: #000000;
}
.subtitle-medium-mobile {
  font-weight: 400;
  font-size: 33px;
  line-height: 43px;
  text-align: center;
  color: #050713;
}
.title-large {
  font-size: 50px;
  line-height: 54px;
  text-align: center;
  color: #050713;
}
.title-large-mobile {
  font-size: 40px;
  line-height: 43px;
  text-align: center;
  color: #050713;
}
.title-large-bold {
  font-size: 40px;
  line-height: 46px;
  color: #303030;
}
.title-large-bold-mobile {
  font-size: 18px;
  line-height: 21px;
  color: #303030;

}
input::placeholder {
  font-family: 'Poppins', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  color: #4A4A4A;
}

.title-medium-red-mobile {
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  color: #145CCC;
}
.subtitle-medium-mobile {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.toggle-label {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #000000;
}

@media (max-width: 800px) {
  .label-fields-peps {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
  }
}
select {
  font-size: initial !important;
}