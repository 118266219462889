@import '../../../assets/styles/brand-colors';
@import '../../../assets/styles/font-styles';

.img-logo-mobile-menu-general {
    width: 193px;
    height: 134px;
    left: 14px;
    top: -7px;
    background: url('../../../assets/images/logomobile.png');
}

.img-logo-home {
    width: 41.9px;
    height: 35.67px;
    margin-top: 20px;
    margin-left: 80px;
    background: url('../../../assets/images/home.png');
}

.img-logo-sarmiento {
    width: 188px;
    height: 80px;
    background: url('../../../assets/images/logo-2blank-white.png');
    background-size: contain; /* o 'cover' si prefieres que cubra todo el contenedor */
  background-repeat: no-repeat; /* Para evitar que la imagen se repita */
  background-position: center; /* Para centrar la imagen dentro del contenedor */
}

.img-logo-black-sarmiento {
    max-width: 20%;
    height: auto;
    object-fit: cover;
}

@media screen and (max-width: 768px) {
    .img-logo-black-sarmiento{
        max-width: 80%;
        margin: 0 auto;
    }
    .img-logo-sarmiento {
        width: 20vh;
        height: 10vh;
        background: url('../../../assets/images/logo-2bank.png');
        background-size: contain; /* o 'cover' si prefieres que cubra todo el contenedor */
  background-repeat: no-repeat; /* Para evitar que la imagen se repita */
  background-position: center; /* Para centrar la imagen dentro del contenedor */
        background-size: contain;
        background-repeat: no-repeat;
        top: 25px;
        position: relative;
    }
}

.v-line {
    border-left: solid #fff;
    margin: 10px;
}

.btn-close-session {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #FFFFFF;
}

.name-user {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 23px;
    display: flex;
    padding: 0 !important;
    align-items: center;
    text-align: right;
    letter-spacing: -0.015em;
    color: #FFFFFF;
}

.menu-init {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
}

.menu-language {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 16px;
    padding: 0 !important;
    color: #FFFFFF;
}