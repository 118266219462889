.detailCreditR{
    padding: 30px;
    .header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 5px 0px 15px;
        h2{
            margin: 0px auto !important;
            font-weight: 500;
            font-size: 36px;
            line-height: 54px;
            color: #145CCC;
        }
    }
    p.presenta{
        font-size: 30px;
        line-height: 36px;
        letter-spacing: -1.5px;
        color: #FFFFFF;
        border: none;
        border-radius: 38px;
        background-color: #2FD32B;
        padding: 5px 15px;
        text-align: center;
    }
    .selectOp{
        display: flex;
        margin: 20px 0px 10px;
        // select{

        // }
    }
    @media (max-width: 768px) {
        .header{
            display: flex;
            button{
                margin-bottom: 100px;
                margin-right: auto;
                width: 100vh;
                display: flex;
            }
            h2{
                margin-bottom: 10px;
            }
            .mensaje{
                display: flex;
                padding-top: 80px;
            }
        }
        p.presenta{
            margin-bottom: 10px;
            font-size: 20px;
            width: 100%;
        }
    }
}
@media (max-width: 768px) {
    .detailCreditR {
        padding: 10px;
    }
}