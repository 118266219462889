@import '../../assets/styles/brand-colors';
.new{
    background: transparent;
    color: $white;
    padding: 20px 60px;
    ol{
        margin: 20px 10px;
        a{
            font-size: 24px;
            color: wheat;
            text-decoration: none;
            text-transform: capitalize;
            margin: 10px;
            li{
                cursor: pointer;
                text-align: left;
                padding: 10px 20px;
                border-radius: 10px;
                background: #0000008c;
                transition: all 0.5s ease;
                width: 100%;
                list-style: decimal;
                &:hover{
                    background: rgba(0, 0, 0, 0.726);
                }
            }
        }
    }
}

.head{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
    .text{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 50%;
        h2{
            font-size: 18px;
        }
        .extra{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-right: 20px;
            li{
                width: 100%;
                text-align: left;
            }
        }
    }
}
.w-10{
    width: 10%;
}
.w-20{
    width: 20%;
}
.w-30{
    width: 30%;
}
.w-40{
    width: 40%;
}
.w-50{
    width: 50%;
}
.w-60{
    width: 60%;
}
.w-70{
    width: 70%;
}
.w-80{
    width: 80%;
}
.w-90{
    width: 90%;
}
.w-100{
    width: 100%;
}
.pdfbody{
    margin: 10px;
    .para{
        border: 1px solid #000;
        padding: 20px;
        p{
            margin-right: 10px;
            color: black;
            font-size: 16px;
            text-align: left;
            font-weight: 500;
            margin-bottom: 0rem;
            line-height: 189%;
        } 
        .fs{
            background-color: #000000;
            padding: 1px;
        }
        .sign-photo{
            display: flex;
            width: 100%;
            align-items: flex-end;
            justify-content: space-around;
            .photo{
                .pic{
                    width: 200px;
                    height: 150px;
                    border: 2px solid #000;
                }
                p{
                    text-align: center;
                    font-size: 12px;
                    font-weight: 600;
                }
            }
        }
    }
    ul{
        display: flex;
        li{
            margin: 10px;
            display: flex;
            align-items: center;
            label{
                margin-right: 10px;
                color: black;
                font-size: 12px;
                text-align: left;
            }
            p{
                margin-right: 10px;
                color: black;
                font-size: 16px;
                text-align: left;
                font-weight: 500;
                margin-bottom: 0rem;
            }
            input{
                height: 40px;
                border-radius: 0px;
                outline: none;
                border: none;
                background-color: #f2f2f2;
                &:focus{
                    outline: none;
                }
            }
            input{
                height: 40px;
                border-radius: 0px;
                outline: none;
                border: none;
                background-color: #f2f2f2;
                &:focus{
                    outline: none;
                }
            }
            .verinputs{
                display: flex;
                width: 100%;
            }
        }
        li.labelup{
            flex-direction: column;
            align-items: flex-start;
        }
        .sublis{
            display: flex;
            align-items: center;
            label{
                margin: 0px 5px 0px 5px;
            }
        }
    }
    ul:nth-child(2){
        li{
            justify-content: space-between;
        }
    }
    .pdftable{
        display: flex;
        justify-content: center;
        text-align: center;
        ul{
            display: flex;
            flex-direction: column;
            width: 30%;
            padding-left: 0rem;
            li{
                display: flex;
                justify-content: center;
                width: 100%;
                padding: 10px;
                border-right: 2px solid #000;
                border-top: 2px solid #000;
                border-bottom: 2px solid #000;
                margin-bottom: 2px;
                margin-top: 2px;
                font-size: 14px;
                height: 45px;
                &:first-child{
                    font-size: 12px;
                    font-weight: 700;
                    background-color: #f2f2f2;
                }
            }
            &:first-child{
                li{
                    border-left: 2px solid #000;
                }
            }
        }
    }
}
.orange{
    color: #FFFFFF;
    padding: 10px;
    font-size: 16px;
    letter-spacing: 130%;
    background-color:#294b8f;
    margin: 10px;
    font-weight: 600;
}
.grey{
    color: #000000;
    padding: 10px;
    font-size: 16px;
    letter-spacing: 130%;
    background-color: #f2f2f2;
    margin: 10px;
    font-weight: 600;
}
.grey.grey-left{
    text-align: left;
    font-size: 14px;
    margin: 10px;
}
.greyOrange{
    color: #FFFFFF;
    padding: 10px;
    margin: 10px;
    h4{
        font-size: 16px;
        margin-bottom: 3px;
    }
    p{
        font-size: 12px;
        margin-bottom: 0px;
    }
    background-color: #1c3464;
}
@media screen and (max-width: 768px) {
    .head{
        flex-direction: column;
        .text{
            width: 100%;
            margin-top: 10px;
            justify-content: space-between;
            h2{
                font-size: 14px;
                text-align: left;
                width: 50%;
                margin-left: 10px;
                letter-spacing: 1px;
            }
            .extra{
                li{
                    font-size: 12px;
                }
            }
        }
    }
    .pdfbody{
        ul{
            flex-direction: column;
            padding-left: 0rem;
            li{
                flex-direction: column;
                margin: 10px 0px;
                align-items: flex-start;
                input{
                    margin-left: 0px !important;
                }
                .sublis.sublis2{
                    flex-direction: column;
                    align-items: flex-start;
                }
                .verinputs{
                    flex-direction: column;
                }
            }
        }
        .pdftable{
            flex-direction: column;
            ul{
                width: 100%;
                border-left: 2px solid #000;
                &:first-child{
                    border-left: 0px solid #000;
                }
            }
        }
        .para{
            .sign-photo{
                flex-direction: column;
                justify-content: flex-end;
                .sign{
                    height: 220px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
            }
        }
    }
    .w-10{
        width: 100% !important;
    }
    .w-20{
        width: 100% !important;
    }
    .w-30{
        width: 100% !important;
    }
    .w-40{
        width: 100% !important;
    }
    .w-50{
        width: 100% !important;
    }
    .w-60{
        width: 100% !important;
    }
    .w-70{
        width: 100% !important;
    }
    .w-80{
        width: 100% !important;
    }
    .w-90{
        width: 100% !important;
    }
    .w-100{
        width: 100% !important;
    }
}